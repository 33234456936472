import { render, staticRenderFns } from "./index.vue?vue&type=template&id=10292489&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TeaserEcm: require('/home/node/app/components/TeaserEcm.vue').default,Section: require('/home/node/app/components/Section.vue').default})
